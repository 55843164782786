<template>
    <div class="form-container" v-if="value">
        <div>
            <div class="mb-10">
                <h3 class="tit tit--xs">Inquirer</h3>
            </div>
            <v-divider class="primary border-2"></v-divider>
            <div class="py-20 px-md-10 py-md-36">
                <div class="mt-n8 mt-md-n14">
                    <myinfo-row label="Name"> {{ form?.writer?.name || "-" }} </myinfo-row>
                    <myinfo-row label="Contact"> {{ form?.writer?.phone || "-" }} </myinfo-row>
                    <myinfo-row label="E-mail"> {{ form?.writer?.email || "-" }} </myinfo-row>
                    <myinfo-row label="User Type"> {{ form?.meta?.isNewUser ? "New" : "Existing" }} </myinfo-row>
                    <myinfo-row label="Dentium History"> {{ form?.meta?.hasUsedDentium ? "Used or In use" : "Never Used" }} </myinfo-row>
                </div>
            </div>
            <v-divider />
        </div>

        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">Inquiry</h3>
            </div>
            <v-divider class="primary border-2"></v-divider>
            <div class="py-20 px-md-10 py-md-36">
                <div class="mt-n8 mt-md-n14">
                    <myinfo-row label="Product of Interest"> {{ form?.meta?.categories?.map?.(({ name }) => name)?.join?.(", ") || "-" }} </myinfo-row>
                    <myinfo-row label="Inquiry"> {{ form?.content || "-" }} </myinfo-row>
                    <myinfo-row label="Other Message"> {{ form?.meta?.message || "-" }} </myinfo-row>
                </div>
            </div>
            <v-divider />
        </div>

        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">Answer</h3>
            </div>
            <v-divider class="primary border-2"></v-divider>
            <v-card tile elevation="0" class="grey-ef">
                <v-card-text class="pa-16 pa-md-24">
                    <div class="page-text" v-html="form?.reply || 'Has no answer yet'"></div>
                </v-card-text>
            </v-card>
        </div>

        <div class="mt-30 mt-md-50">
            <div class="v-btn--group v-btn--group--large">
                <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="$router.go(-1)"><span class="primary--text">Back to List</span></v-btn>
                <!-- <v-btn x-large color="primary" class="w-100 mw-220px" @click="$emit('save')">위탁신청</v-btn> -->
            </div>
        </div>
    </div>
</template>

<script>
import MyinfoRow from "@/components/client/mypage/myinfo-row.vue";
import ImagePopup from "@/components/console/dumb/image-popup.vue";

export default {
    components: {
        MyinfoRow,
        ImagePopup,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            form: this.$props.value,
        };
    },
    watch: {
        value() {
            this.form = this.value;
        },
    },
    methods: {
        input() {
            this.$emit("input", this.form);
        },
    },
};
</script>
