var render = function render(){
  var _vm$form, _vm$form$writer, _vm$form2, _vm$form2$writer, _vm$form3, _vm$form3$writer, _vm$form4, _vm$form4$meta, _vm$form5, _vm$form5$meta, _vm$form6, _vm$form6$meta, _vm$form6$meta$catego, _vm$form6$meta$catego2, _vm$form6$meta$catego3, _vm$form6$meta$catego4, _vm$form7, _vm$form8, _vm$form8$meta, _vm$form9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('div', {
    staticClass: "form-container"
  }, [_c('div', [_vm._m(0), _c('v-divider', {
    staticClass: "primary border-2"
  }), _c('div', {
    staticClass: "py-20 px-md-10 py-md-36"
  }, [_c('div', {
    staticClass: "mt-n8 mt-md-n14"
  }, [_c('myinfo-row', {
    attrs: {
      "label": "Name"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$writer = _vm$form.writer) === null || _vm$form$writer === void 0 ? void 0 : _vm$form$writer.name) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "Contact"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$writer = _vm$form2.writer) === null || _vm$form2$writer === void 0 ? void 0 : _vm$form2$writer.phone) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "E-mail"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : (_vm$form3$writer = _vm$form3.writer) === null || _vm$form3$writer === void 0 ? void 0 : _vm$form3$writer.email) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "User Type"
    }
  }, [_vm._v(" " + _vm._s((_vm$form4 = _vm.form) !== null && _vm$form4 !== void 0 && (_vm$form4$meta = _vm$form4.meta) !== null && _vm$form4$meta !== void 0 && _vm$form4$meta.isNewUser ? "New" : "Existing") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "Dentium History"
    }
  }, [_vm._v(" " + _vm._s((_vm$form5 = _vm.form) !== null && _vm$form5 !== void 0 && (_vm$form5$meta = _vm$form5.meta) !== null && _vm$form5$meta !== void 0 && _vm$form5$meta.hasUsedDentium ? "Used or In use" : "Never Used") + " ")])], 1)]), _c('v-divider')], 1), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(1), _c('v-divider', {
    staticClass: "primary border-2"
  }), _c('div', {
    staticClass: "py-20 px-md-10 py-md-36"
  }, [_c('div', {
    staticClass: "mt-n8 mt-md-n14"
  }, [_c('myinfo-row', {
    attrs: {
      "label": "Product of Interest"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form6 = _vm.form) === null || _vm$form6 === void 0 ? void 0 : (_vm$form6$meta = _vm$form6.meta) === null || _vm$form6$meta === void 0 ? void 0 : (_vm$form6$meta$catego = _vm$form6$meta.categories) === null || _vm$form6$meta$catego === void 0 ? void 0 : (_vm$form6$meta$catego2 = _vm$form6$meta$catego.map) === null || _vm$form6$meta$catego2 === void 0 ? void 0 : (_vm$form6$meta$catego3 = _vm$form6$meta$catego2.call(_vm$form6$meta$catego, function (_ref) {
    var name = _ref.name;
    return name;
  })) === null || _vm$form6$meta$catego3 === void 0 ? void 0 : (_vm$form6$meta$catego4 = _vm$form6$meta$catego3.join) === null || _vm$form6$meta$catego4 === void 0 ? void 0 : _vm$form6$meta$catego4.call(_vm$form6$meta$catego3, ", ")) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "Inquiry"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form7 = _vm.form) === null || _vm$form7 === void 0 ? void 0 : _vm$form7.content) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "Other Message"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form8 = _vm.form) === null || _vm$form8 === void 0 ? void 0 : (_vm$form8$meta = _vm$form8.meta) === null || _vm$form8$meta === void 0 ? void 0 : _vm$form8$meta.message) || "-") + " ")])], 1)]), _c('v-divider')], 1), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(2), _c('v-divider', {
    staticClass: "primary border-2"
  }), _c('v-card', {
    staticClass: "grey-ef",
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-16 pa-md-24"
  }, [_c('div', {
    staticClass: "page-text",
    domProps: {
      "innerHTML": _vm._s(((_vm$form9 = _vm.form) === null || _vm$form9 === void 0 ? void 0 : _vm$form9.reply) || 'Has no answer yet')
    }
  })])], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('div', {
    staticClass: "v-btn--group v-btn--group--large"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "color": "grey-cb"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("Back to List")])])], 1)])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("Inquirer")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("Inquiry")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("Answer")])]);

}]

export { render, staticRenderFns }